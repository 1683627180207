import { Box, Flex, Text } from "@chakra-ui/react";
import Header from "./Header";
import InputField from "./InputField";
import ChatCard from "./ChatCard";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";


const ChatBox1: React.FC = () => {
    const navigate = useNavigate();
    const context: any = useContext(AppContext);
    const { setStoryList,setStories } = context;

    const settings = {
        dots: false,
        infinite: false,
        speed: 200,
        centerMode: true,
        slidesToShow: 1.2,
        centerPadding: '15px',
        arrows: false,
        autoplay: false
    };

    const handleCardClick = (title: any) => {
        setStoryList([
            {
                id: 1,
                title: "Bangkok Bold kitchen",
                images: [
                    "/images/dish1.jpg",
                    "/images/dish1.jpg",
                    "/images/dish1.jpg"
                ],
                subtitle: "LGF, Central Embassy, 1031 Phloen Chit Road, Lumphini, Pathum Wan, Bangkok, 10330",
                details: "Word-of-mouth inspired Chef Nhoi to take the leap from cooking studio in the old town to opening a restaurant in the city centre. Her aim is to preserve the heritage of Thai taste that’s ‘basic and bold’ with creative twists."
            },
            {
                id: 2,
                title: "Haoma Bangkok",
                images: [
                    "/images/dish3.jpg",
                    "/images/dish3.jpg",
                    "/images/dish3.jpg",
                    "/images/dish3.jpg",
                    "/images/dish3.jpg",
                    "/images/dish3.jpg"
                ],
                subtitle: "Discover Haoma · Reserve Your Table. The Chef · Tasting Menus - 15 courses",
                details: "Embark on a Neo-Indian fine dining journey in Bangkok, led by our dedicated restaurant manager. Every dish reflects sustainability."
            }
        ]);
        setStories(title);
        navigate('/home/stories');
    }

return (
    <Flex
        bg={'#FFFFFF'}
        direction={'column'}
        minH='100vh'
        w='100vw'
        position='relative'
    >
        {/* Header remains fixed */}
        <Header
            backIconSrc="/icons/arrow_back.svg"
            chatIconSrc="/icons/chat_filled.svg"
            addIconSrc="/icons/plus_outline.svg"
            backNavigationPath="/home"
            chatButtonOnClick={() => { }}
            bgColor="#2F2F320D"
            borderColor="linear-gradient(135deg, #FF7E5F, #FEB47B)"
        />
        {/* Chat Topic Bar */}
        <Flex
            borderTop={"1px solid #3A3C4221"}
            mt={'5px'}
            pt={'8px'}
            pb={'7px'}
            pl={'18px'}
            boxShadow={"0px 7px 7px 0px #00000008"}
        >
            <Text
                fontSize={'11px'}
                fontFamily={'500'}
                letterSpacing={'-0.47px'}
                color={'#16181BA1'}
            >
                Best michelin star restaurants in Bangkok
            </Text>
        </Flex>

        <Box
            flex={1}
            overflowY="auto"
            position={'relative'}
            px={4}
            py={4}
            mb={'10px'}
        >
            {/* Example Message */}
            <Flex direction={"column"}>
                <Box bg={'#E8E9FF'} p={'10px'} borderRadius={'8px'} maxW={'227px'} mt={'30px'}>
                    <Text color={'#16181B'} fontSize={'14px'} fontWeight={600}>
                        Here are our recommendations based on your preference
                    </Text>
                </Box >
                <Text color={'#B5B5B5'}
                    size={'11px'}
                    mt={2}
                    letterSpacing={'-0.47px'}
                >
                    7:30 PM, 1st April
                </Text>
            </Flex>

            {/* Slider */}
            <Box pt='12px' pb={'12px'} h={"auto"} >
                <Slider {...settings}>
                    <ChatCard
                        imageSrc='/images/dish1.jpg'
                        altText='dish1'
                        title='Bangkok Bold Kitchen'
                        subtitle='Khwaeng Silom'
                        buttonText='View Details'
                        onCardClick={() => handleCardClick('Bangkok Bold Kitchen')}

                    />
                    <ChatCard
                        imageSrc='/images/dish2.png'
                        altText='dish2'
                        title='Haoma Bangkok'
                        subtitle='Khwaeng Silom'
                        buttonText='View Details'
                        onCardClick={() => handleCardClick('Haoma Bangkok')}
                    />
                </Slider>
            </Box>
        </Box>
        <Box
            position={'sticky'}
            bottom={'10px'}
            bg={'white'}
            px={'31px'}
            py={3}
            w={'full'}
        >
            <InputField placeholder="Type here..." />
        </Box>
    </Flex >
);
};

export default ChatBox1;
