import React, { useEffect, useState } from "react";
import MoreFromInfluecerCard from "./MoreFromInfluecerCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Box, Container, SimpleGrid, Flex } from "@chakra-ui/react";
import TrOutlineButton from "./TrOutlineButton";
import { pixels } from "../theme";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";
import useAxios from "../hooks/useAxios";

const CustomLeftArrow = ({ onClick }) => {
  const style = {
    position: "absolute",
    cursor: "pointer",
    zIndex: "1",
    left: "-5px",
    top: "85px",
  };
  return (
    <button onClick={onClick} style={style} className="custom-left-arrow">
      <img src="/icons/left.svg" alt="SVG Button" width="50" height="50" />
    </button>
  );
};

const CustomRightArrow = ({ onClick }) => {
  const style = {
    position: "absolute",
    cursor: "pointer",
    zIndex: "1",
    right: "-5px",
    top: "85px",
  };
  return (
    <button
      onClick={onClick}
      className="custom-right-arrow"
      position="absolute"
      style={style}
    >
      <img src="/icons/right.svg" alt="SVG Button" width="50" height="50" />
    </button>
  );
};

const CardSlider = (props) => {
  const [visibleCount, setVisibleCount] = useState(4);
  const otherPlacesState = useContext(AppContext);
  const { loading, fetchData } = useAxios();
	const {
		searchDetail,
	}= useContext(AppContext);

  useEffect(() => {
    const fetchOtherPlaces = async () => {
      const { data, statusCode, error } = await fetchData({
        method: "GET",
        url: `ai/other-places`,
        options: {
          data: {},
          headers: { "Content-Type": "application/json" },
        },
        auth: false,
      });

      console.log("other places data", {
        data,
        statusCode,
        error,
        loading,
      });

      otherPlacesState.setOtherPlaces(data);
    };

    fetchOtherPlaces();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 9,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1160 },
      items: 6,
    },

    tablet: {
      breakpoint: { max: 1160, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  const showMoreCards = () => {
    if (visibleCount === 4) {
      setVisibleCount(otherPlacesState?.otherPlaces?.length);
    } else setVisibleCount(4);
  };

  console.log(otherPlacesState?.otherPlaces?.length)
  return (
    <>
      {/* desktop */}

      <Box display={{ base: "none", lg: "block", md: "block" }}>
        {searchDetail && searchDetail?.entities && searchDetail?.entities.length > 0 ? 
        <Carousel
          color="white"
          responsive={responsive}
          customLeftArrow={<CustomLeftArrow />}
          customRightArrow={<CustomRightArrow />}
          containerClass="carousel-container influencer-card-carousel"
          itemClass="carousel-item"
          autoPlay
          rewind={true}
        >
          {searchDetail?.entities.map((value, index) => {
            return (
              <React.Fragment key={index}>
                <MoreFromInfluecerCard
                  key={index}
                  index={index}
                  placeName={value["title"]}
                  imageUrl={value["previewImage"]}
                  videoUrl={value["url"]}
                  boxSize={"200px"}
                  width={"160px"}
                />
              </React.Fragment>
            )
          })}
        </Carousel>: ''}
      </Box>
      {/* mobile */}
      <Box display={{ base: "block", lg: "none", md: "none" }}>
        <Container p={"0px"} m={"0px"}>
          <SimpleGrid gap={pixels["20pixels"]} columns={2} spacing={5}>
            {otherPlacesState.otherPlaces ? otherPlacesState.otherPlaces
              .slice(0, visibleCount)
              .map((value, index) => {
                console.log("value", value)
                return (
                  <React.Fragment key={index}>
                    <MoreFromInfluecerCard
                      onClose={props?.onClose}
                      index={index}
                      key={index}
                      placeName={value["title"]}
                      imageUrl={value["thumbnailUrl"]}
                      videoUrl={value["url"]}
                      boxSize={"150px"}
                      width={"100%"}
                    />
                  </React.Fragment>
                );
              }): ''}
          </SimpleGrid>

          <Flex onClick={showMoreCards} justifyContent={"center"} pt={"15px"}>
            <TrOutlineButton color={{ base: 'black', lg: 'white'}} borderRadius={"99px"} px={pixels["20pixels"]}>
              {visibleCount === otherPlacesState.setOtherPlaces?.length
                ? "Show Less"
                : "Show More"}
            </TrOutlineButton>
          </Flex>
        </Container>
      </Box>
    </>
  );
};

export default CardSlider;
