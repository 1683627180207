import { Box, Flex, SimpleGrid } from "@chakra-ui/react";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import DestinationCard from "../components/Collection-flow/DestinationCard";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NotificationBubble from "../components/Collection-flow/NotificationBubble";

const destinations = [
    {
        imageSrc: "/images/iceland.jpg",
        headingText: "Iceland",
        subheadingText: "Northern lights spots"
    },
    {
        imageSrc: "/images/iceland.jpg",
        headingText: "Europe",
        subheadingText: "Southern"
    },
    {
        imageSrc: "/images/iceland.jpg",
        headingText: "South Asia",
        subheadingText: "memorable"
    },
    {
        imageSrc: "/images/iceland.jpg",
        headingText: "Night Life",
        subheadingText: "Best of"
    },
    {
        imageSrc: "/images/iceland.jpg",
        headingText: "Switzerland",
        subheadingText: "Enchanting"
    },
    {
        imageSrc: "/images/iceland.jpg",
        headingText: "Japan",
        subheadingText: "Best of"
    }
];

const Collection: React.FC = () => {
    const navigate = useNavigate();
    useEffect(() => {
        // setTimeout(() => {
        //     navigate('/home/modal');
        // }, 6000);
    }, [navigate]);
    return (
        <Box
            minH="100vh"
            w="100vw"
            overflow="hidden"
        >
            <Box
                position="relative"
                zIndex={1}
                px="12px">
                <Box mb="40vh">

                    <SimpleGrid columns={2} spacing={3} alignItems={'center'} justifyContent={'center'} >
                        {destinations.map((destination, index) => (
                            <DestinationCard
                                key={index}
                                imageSrc={destination.imageSrc}
                                headingText={destination.headingText}
                                subheadingText={destination.subheadingText}
                            />
                        ))}
                    </SimpleGrid>

                </Box>
            </Box>
            <Flex
                direction="column"
                zIndex={200}
                position="absolute"
                alignItems="center"
                pb="15vh"
                bottom="0"
                left={0}
                w="full">
                <NotificationBubble />
            </Flex>
        </Box>

    );
}

export default Collection;