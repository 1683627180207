import React, { useEffect, useState } from 'react';
import {
    Box,
    VStack,
    Skeleton,
} from "@chakra-ui/react";
import ConversationCard from "./ConversationCard";
import SearchBar from "../CreateItinary/SearchBar";
import Header from "./Header";
import { useNavigate } from 'react-router-dom';

const SavedConversation: React.FC = () => {
    const apiUrl = 'https://us-central1-traverse-project-421916.cloudfunctions.net/conversation-memory/find_all_conversation_title_for_a_user';

    // State to store conversations and loading state
    const navigate=useNavigate();
    const [conversations, setConversations] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    // Function to fetch conversations from API
    const fetchChatResponse = async (query: string) => {
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: query,
                })
            });

            if (!response.ok) {
                const text = await response.text();
                console.error('Error Response Body:', text);
                throw new Error(`HTTP error! status: ${response.status}, body: ${text}`);
            }

            const data = await response.json();
            setConversations(data); // Set the data in state
        } catch (error) {
            console.error('Fetch error:', error);
        } finally {
            setLoading(false); // Stop loading once the API call is complete
        }
    };

    // useEffect to fetch conversations when the component is mounted
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token)
            fetchChatResponse(token);  // Fetch conversations with the token
    }, []);  // Empty dependency array means this runs once when the component is first rendered

    return (
        <VStack bg='black' minH={"100vh"} minW={"100vw"}>
            <Box
                position={'fixed'}
                top={0}
                left={0}
                w={'100vw'}
                h={'50vh'}
                zIndex={'2'}
                bg={"linear-gradient(180deg, #252128 0%, rgba(36, 29, 50, 0) 100%)"}
            />
            {/* Header */}
            <Box
                bg={'#29252D'}
                position={"fixed"}
                top={0}
                left={0}
                w={"100vw"}
                h={'10vh'}
                pt={'14px'}
                zIndex={10}
            >
                <Header
                    backIconSrc="/icons/Back_Icon_White.svg"
                    heading="Saved Conversations"
                    addIconSrc="/icons/plus_outline.svg"
                    backNavigationPath="/home/chat"
                    addButtonOnClick={() => { navigate('/home/chat');
                        window.location.reload();}}
                    bgColor="#2E2B31"
                />
            </Box>

            {/* Skeleton for the entire content while loading */}
            <VStack flexDir={'column'} spacing={'20px'} zIndex={5} mt={'10vh'}  >
                {/* Search Bar */}
                <SearchBar />
                {/* Conversation List */}
                <Skeleton isLoaded={!loading}>
                    <VStack spacing={4}>
                        {conversations.map((conversation, index) => (
                            <ConversationCard
                                key={index}  // Using index as the key since there's no id
                                title={conversation}
                                image=""
                            />
                        ))}
                    </VStack>
                </Skeleton>
            </VStack>
        </VStack >
    );
};

export default SavedConversation;
