// import { ArrowBackIcon, EditIcon } from "@chakra-ui/icons";
import { VStack, Image, Heading, Box, Button, IconButton } from "@chakra-ui/react"
import Detail from "./Detail";
import { useNavigate } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { useEffect, useState } from "react";
import ItineraryFooter from "../CreateItinary/ItineraryFooter";

const ProfileView: React.FC = () => {
  const [phoneno, setPhoneNo] = useState("+91-9877627682");
  const { fetchData } = useAxios();
  useEffect(() => {
    const fetchUserDetail = async () => {
      const { data } = await fetchData({
        method: "GET",
        url: `user/profile`,
        options: {
          data: {},
          headers: { "Content-Type": "application/json" },
        },
        auth: true,
      });
      if (data)
        setPhoneNo(data.phone_number);
    };

    fetchUserDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const router = useNavigate();
  return (
    <>
      <Box
        position="fixed"
        top="0"
        left="0"
        width="100vw"
        height="100vh"
        bgColor="blackAlpha.900"
        zIndex={2}   // Ensures it appears above other content
      />
      <Box position="fixed"
        zIndex={5}
        top="6vh"
        left="3vw">
        <IconButton
          aria-label='Back'
          icon={<Image src="/icons/Back_Icon_White.svg" alt="back" />}
          colorScheme='gray'
          variant={'link'}
          size='lg'
          onClick={() => {
            router(`/home`);
          }}
        />
      </Box>
      <VStack mt={'30vh'} bg={'#242127'} spacing={0} h={'70vh'} zIndex={5}>
        <Image src={'/images/user.png'} alt='user' boxSize="72px" borderRadius="full" objectFit={'cover'} border={"3px solid #8A63C8"} mt={-37} />
        <Heading
          mt={'25px'}
          fontFamily="Poppins"
          fontSize="20px"
          fontWeight="600"
          lineHeight="20px"
          letterSpacing="-0.5px"
          textAlign="center"
          mb={'36px'}
          color={'#FFFFFF'}
        >
          Anjali Menon
        </Heading>
        <Detail
          label="Email"
          value="anjali.menon@gmail.com"
          buttonText="Edit"
          onEditClick={() => console.log('Edit button clicked')}
        />
        <Detail
          label="Phone"
          buttonText="Edit"
          value={phoneno}
          onEditClick={() => console.log('Edit button clicked')}
        />
        <Detail
          label="Saved"
          value="Itineraries"
          buttonText="View"
          onEditClick={() => console.log('Edit button clicked')}
        />
        <Button
          mt={'54px'}
          w="74px"
          h="20px"
          variant="link"
          fontFamily="Poppins"
          fontSize="20px"
          fontWeight="600"
          lineHeight="20px"
          letterSpacing="-0.5px"
          textAlign="center"
          color="#AE7CFF"
          onClick={() => {
            localStorage.removeItem('token');
            router('/home');
            window.location.reload();

          }}
        >
          Log Out
        </Button>
      </VStack>
      <ItineraryFooter />
    </>
  )
};
export default ProfileView;
