import React, { useState, useEffect } from 'react';
import {
	Flex,
	Box,
	Text,
	Divider,
	HStack,
	Image,
	Alert,
	AlertIcon,
	Heading,
	VStack,
	Button,
} from '@chakra-ui/react';
import TraverseBlackLogo from './TraverseBlackLogo';
import SocialAuthButton from './Buttons/SocialAuthButtons';
import { pixels } from '../theme';
import PhoneNumberInput from './PhoneNumberInput';
import TrPrimaryButton from './TrPrimaryButton';
import useAxios from '../hooks/useAxios';
import { useNavigate } from 'react-router-dom';
import { isValidPhoneNumber } from 'react-phone-number-input';
import VerifyCode from './VerrifyCode';

interface LoginFields {
	phone_number: string;
}
interface SigninComponentProps {
	onClose: () => void;
}

const validateFields = (values: LoginFields) => {
	const errors: Partial<LoginFields> = {};

	if (!values.phone_number) {
		errors.phone_number = 'Phone number is required';
	} else if (!isValidPhoneNumber(values.phone_number)) {
		errors.phone_number = 'Invalid phone number';
	}

	return errors;
};

const SigninComponent: React.FC<SigninComponentProps> = ({ onClose }) => {
	const navigate = useNavigate();
	const { loading, fetchData } = useAxios();
	const { loading: loading2, fetchData: fetchData2 } = useAxios();
	const initLoginFields: LoginFields = { phone_number: '' };
	const [loginFields, setLoginFields] = useState<LoginFields>(initLoginFields);
	const [errors, setErrors] = useState<Partial<LoginFields>>({});
	const [serverError, setServerError] = useState<string>('');
	const [showVerifyCodeForm, setShowVerifyCodeForm] = useState<boolean>(false);

	const handleSkipToHomepage = () => {
		navigate('/home'); // Navigate to the homepage
		onClose(); // Close the modal
	};


	useEffect(() => {
		const handleAuthCallback = () => {
			const token = localStorage.getItem('token');
			if (token) {
				// alert(token);
				// localStorage.removeItem('token');
				// navigate('/');
			}
		};

		window.addEventListener('storage', handleAuthCallback);

		return () => {
			window.removeEventListener('storage', handleAuthCallback);
		};
	}, [navigate]);

	const setPhoneValue = (value: string) => {
		setErrors({ ...errors, phone_number: '' });
		setLoginFields({ ...loginFields, phone_number: value });
	};

	const callbackActionAfterLogin = (token: string) => {
		if (token) {
			localStorage.setItem('token', token);
		}
		window.location.reload();
	};

	const login = async () => {
		const formErrors = validateFields(loginFields);

		if (Object.keys(formErrors).length === 0) {
			const { statusCode, error } = await fetchData({
				method: 'POST',
				url: 'login/phone/request-code',
				options: {
					data: loginFields,
					headers: { 'Content-Type': 'application/json' },
				},
				auth: false,
			});
			if (statusCode === 200) {
				// setErrors({ initLoginFields });
				setServerError('');
				setShowVerifyCodeForm(true);
			} else {
				setServerError(JSON.stringify(error));
			}
		} else {
			setErrors(formErrors);
		}
	};

	const socialAuth = async (name: string) => {
		try {
			const response = await fetchData2({
				method: 'GET',
				url: `authorize/${name}`,
				options: {
					headers: { 'Content-Type': 'application/json' },
				},
				auth: false,
			});
			if (response?.data?.auth_url) {
				window.location.href = response.data.auth_url;
			} else {
				setServerError('Failed to get authorization URL');
			}
		} catch (error) {
			setServerError('Failed to get authorization URL');
		}
	};

	return (
		<Flex
			sx={{
				borderRadius: { base: 0, lg: '24px' },
				width: { base: '100%', lg: '984px' },
				height: { base: '100%', lg: '561px' },
				background: { base: `url('/images/bg_images/loginBg.jpg')`, lg: 'transparent' }, // Mobile background image
				backgroundPosition: { base: 'center', lg: 'unset' },
				backgroundRepeat: { base: 'no-repeat', lg: 'unset' },
				backgroundSize: { base: 'cover', lg: 'unset' },
				justifyContent: { base: 'center', lg: 'unset' },
			}}
		>

			{/* Desktop View */}
			<Image
				display={{ base: 'none', lg: 'unset' }}
				borderRadius="33px 0px 0px 33px"
				src="/images/left-side-login-background.png"
				alt="login-to-traverse-image"
			/>

			<Flex
				w="100%"
				alignItems="center"
				flexDir="column"
				p={pixels['16pixels']}
				pt={{ base: pixels['30pixels'], lg: '92px' }}
			>
				{/* Desktop-specific content */}
				<Box
					w={{ base: '100%', lg: '420px' }}
					mx="auto"
					display={{ base: 'none', lg: 'flex' }} // Visible only on desktop
					alignItems="center"
					flexDir="column"
					gap={pixels['16pixels']}
				>
					{serverError && (
						<Alert status="error">
							<AlertIcon />
							{serverError}
						</Alert>
					)}
					<Box mx="auto">
						<TraverseBlackLogo />
					</Box>

					<Text
						sx={{
							color: 'rgba(22, 21, 23, 0.63)',
							fontFamily: 'Poppins',
							fontSize: '16px',
							fontWeight: '500',
							lineHeight: '18px',
							mx: 'auto',
						}}
					>
						{!showVerifyCodeForm ? 'Please log in to continue' : 'Please type verification code'}
					</Text>

					{!showVerifyCodeForm ? (
						<>
							<SocialAuthButton
								onClick={() => socialAuth('facebook')}
								colorScheme="trFacebookBlue"
								iconUrl="/icons/facebook.svg"
								name="facebook"
								isLoading={loading2}
							/>

							<SocialAuthButton
								colorScheme="trInstagramBlack"
								iconUrl="/icons/instagram.svg"
								name="instagram"
								onClick={() => socialAuth('instagram')}
								isLoading={loading2}
							/>

							<HStack borderColor="trGrey">
								<Divider w="118px" borderColor="trGrey" />
								<Text
									mx={pixels['10pixels']}
									textStyle="sm"
									whiteSpace="nowrap"
									color="trGreyFont63"
								>
									or
								</Text>
								<Divider w="118px" borderColor="trGrey" />
							</HStack>

							<PhoneNumberInput
								isInvalid={!!errors.phone_number}
								isDisable={loading || loading2}
								value={loginFields.phone_number}
								onChange={(value) => setPhoneValue(value)}
							/>

							<TrPrimaryButton
								height="44px"
								fontSize="16px"
								w="100%"
								isDisabled={loading}
								onClick={login}
								isLoading={loading2}
							>
								Send OTP
							</TrPrimaryButton>
						</>
					) : (
						<VerifyCode
							onVerifySuccess={callbackActionAfterLogin}
							phone_number={loginFields.phone_number}
						/>
					)}
				</Box>

				{/* Mobile-specific content */}
				<Box
					display={{ base: 'flex', lg: 'none' }} // Visible only on mobile
					h={'100vh'}
					w={'100vw'}
					position={'fixed'}
					top={0}
					bottom={0}
					bg="linear-gradient(180deg, rgba(0, 0, 0, 0) 36.62%, rgba(0, 0, 0, 0.93) 92.61%), linear-gradient(180deg, rgba(0, 0, 0, 0.63) 6.48%, rgba(0, 0, 0, 0) 48.59%)"
				/>
				<Flex
					zIndex={3}
					display={{ base: 'flex', lg: 'none' }} // Visible only on mobile
					w="100%"
					mx="auto"
					alignItems="center"


					justifyContent={'space-between'}
					flexDir="column"
					h={'85vh'}
					gap={pixels['16pixels']}
				>
					<VStack>
						<Image src="/icons/logo.svg" alt="logo" w="42.27px" />

						<VStack alignItems="center">
							<Heading
								fontFamily="Poppins"
								fontSize="26.97px"
								fontWeight="700"
								lineHeight="32.59px"
								letterSpacing="-0.34px"
								textAlign="left"
								color="#FFFFFFED"
								backdropFilter="blur(4px)"
								p={0}>
								TRAVERSE
							</Heading>
							<Heading
								fontFamily="Poppins"
								fontSize="11px"
								mt={-2}
								fontWeight="500"
								lineHeight="13.2px"
								letterSpacing="-2%"
								textAlign="center"
								color="#EEEEEEA1"
							>
								SIGN IN TO CONTINUE
							</Heading>
						</VStack>
					</VStack>
					<Box>
						{!showVerifyCodeForm ? (
							<VStack spacing={4}>
								<SocialAuthButton
									onClick={() => socialAuth('facebook')}
									colorScheme="#0866FF"
									iconUrl="/icons/facebook.svg"
									name="Facebook"
									isLoading={loading2}
								/>

								<SocialAuthButton
									colorScheme="linear-gradient(90deg, rgba(93, 84, 202, 0.99) 0%, rgba(167, 79, 148, 0.99) 51%, rgba(238, 75, 96, 0.99) 100%)"
									iconUrl="/icons/instagram.svg"
									name="Instagram"
									onClick={() => socialAuth('instagram')}
									isLoading={loading2}
								/>

								<HStack borderColor="trGrey">
									<Divider w="118px" borderColor="white" />
									<Text
										mx={pixels['10pixels']}
										textStyle="sm"
										whiteSpace="nowrap"
										color="white"
									>
										or
									</Text>
									<Divider w="118px" borderColor="white" />
								</HStack>

								<PhoneNumberInput
									isInvalid={!!errors.phone_number}
									isDisable={loading || loading2}
									value={loginFields.phone_number}
									onChange={(value) => setPhoneValue(value)}
								/>
								<Button
									display={'flex'}
									justifyContent={'space-between'}
									w={'100%'}
									h={'58px'}
									borderRadius={'99px'}
									border="1px solid #FFFFFF21"
									px={'24px'}
									onClick={login}
									bg={'#FFFFFF'}>
									<Text
										fontFamily="Poppins"
										fontSize="13px"
										fontWeight="400"
										lineHeight="18px"
										textAlign="left"
										color={'#16181B'}>
										Login using Phone / Whatsapp
									</Text>
									<Image src={'/icons/arrow-right.svg'} alt={'arrow'} />

								</Button>
								<Button
									onClick={handleSkipToHomepage}
									mt={'34px'}
									_hover={{ bg: 'none' }}
									variant={'Link'}
								>
									<Text
										fontFamily="Poppins"
										fontSize="13px"
										fontWeight="500"
										lineHeight="18px"
										textAlign="center"
										color={'#FFFFFFA1'}
									>
										Skip to Homepage
									</Text>
								</Button>

							</VStack>
						) : (
							<VerifyCode
								onVerifySuccess={callbackActionAfterLogin}
								phone_number={loginFields.phone_number}
							/>
						)}
					</Box>
				</Flex>
			</Flex>
		</Flex>


	);
};

export default SigninComponent;
