import React from 'react';
import { Box, Text, UnorderedList, ListItem } from '@chakra-ui/react';

interface GenericCardProps {
    title?: string;
    subtitle?: string;
    pros?: string[];
    cons?: string[];
    list?: string[];
}

const GenericCard: React.FC<GenericCardProps> = ({ title, pros, cons, list, subtitle }) => {
    return (
        <Box>
            {/* Render title if provided */}
            {title && (
                <Text
                    fontFamily="Poppins"
                    fontSize="20px"
                    fontWeight="700"
                    lineHeight="24px"
                    letterSpacing="-0.3px"
                    textAlign="left"
                    color={'#FFFFFFD4'}
                    
                >
                    {title}
                </Text>
            )}

            {/* Conditionally render the subtitle */}
            {subtitle && (
                <Text
                    fontFamily="Poppins"
                    fontSize="14px"
                    fontWeight="500"
                    lineHeight="18.62px"
                    textAlign="left"
                    color={'#FFFFFFA1'}
                    mt={4}>
                    {subtitle}
                </Text>
            )}

            {/* Render Pros section */}
            {pros && (
                <Box mt={6}>
                    <Text
                        fontFamily="Poppins"
                        fontSize="14px"
                        fontWeight="700"
                        lineHeight="18.62px"
                        letterSpacing="5px"
                        textAlign="left"
                        color={'#FFFFFFED'}>
                        PROS:
                    </Text>
                    <UnorderedList mt={2} spacing={4}>
                        {pros.map((pro, index) => (
                            <ListItem
                                key={index}
                                fontFamily="Poppins"
                                fontSize="14px"
                                fontWeight="500"
                                lineHeight="18.62px"
                                textAlign="left"
                                color={'#FFFFFFA1'}>
                                {pro}
                            </ListItem>
                        ))}
                    </UnorderedList>
                </Box>
            )}
            {cons && (
                <Box mt={6}>
                    <Text
                        fontFamily="Poppins"
                        fontSize="14px"
                        fontWeight="700"
                        lineHeight="18.62px"
                        letterSpacing="5px"
                        textAlign="left"
                        color={'#FFFFFFED'}
                    >
                        CONS:
                    </Text>
                    <UnorderedList mt={2} spacing={4}>
                        {cons.map((con, index) => (
                            <ListItem
                                key={index}
                                fontFamily="Poppins"
                                fontSize="14px"
                                fontWeight="500"
                                lineHeight="18.62px"
                                textAlign="left"
                                color={'#FFFFFFA1'}>
                                {con}
                            </ListItem>
                        ))}
                    </UnorderedList>
                </Box>
            )}
            {list&&(
            <UnorderedList mt={'24px'} spacing={6}>
                {list.map((lis, index) => (
                    <ListItem
                        key={index}
                        fontFamily="Poppins"
                        fontSize="14px"
                        fontWeight="500"
                        lineHeight="18.62px"
                        textAlign="left"
                        color={'#FFFFFFA1'}>
                        {lis}
                    </ListItem>
                ))}
            </UnorderedList>)}
        </Box>
    );
};

export default GenericCard;
