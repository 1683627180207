import { Flex, Image, Text } from "@chakra-ui/react";
import { useContext, useEffect, useState, useCallback } from "react";
import { AppContext } from "../../context/AppContext";

interface InfluencerrIdProps {
    infId: string;
}

const InfluencerId: React.FC<InfluencerrIdProps> = ({ infId }) => {
    const context: any = useContext(AppContext);
    const { influencers, setInfluencers } = context;
    const [inf, setInf] = useState({ id: Number, name: "", infId: "", followers: "" ,profilePic:""});

    const onStart = useCallback(() => {
        if (influencers && influencers.length > 0) {
            const influencer = influencers.find((inf: any) => inf.infId === infId);
            if (influencer) {
                setInf(influencer);
            } else {
                console.log(`Influencer with infId ${infId} not found`);
            }
        }
    }, [influencers, infId]);

    useEffect(() => {
        setInfluencers([
            { id: 1, name: "Sonali Ghosh", infId: "@ Indrani Ghose", followers: "356k" ,profilePic:"/images/user1.jpg"},
            { id: 2, name: "Siddharth Joshi", infId: "@ Siddharth Joshi", followers: "356k",profilePic:"/images/user2.jpg" },
            { id: 3, name: "Anunay Sood", infId: "@ Anunay Sood", followers: "356k",profilePic:"/images/user3.jpg" },
        ]);
    }, [setInfluencers]);

    useEffect(() => {
        onStart();
    }, [onStart]);

    return (
        <Flex justifyContent={'space-between'} alignItems={'space-between'} gap={'10px'}>
            <Image
                src={inf.profilePic}
                alt=""
                boxSize={"40px"}
                borderRadius={'full'}
                border={'1px solid #878787'}
            />
            <Flex flexDir={'column'}>
                <Text
                    fontFamily="Poppins"
                    fontSize="16px"
                    fontWeight="700"
                    lineHeight="19px"
                    letterSpacing="-0.699999988079071px"
                    textAlign="left"
                    color={'#FFFFFFFC'}
                >
                    {inf.infId}
                </Text>
                <Text
                    fontFamily="Poppins"
                    fontSize="13px"
                    fontWeight="700"
                    lineHeight="19px"
                    color={'#D9D9D9'}
                    letterSpacing="-0.699999988079071px"
                    textAlign="left"
                >
                    {inf.followers}
                    <Text as='span' color={'#878787'}>
                        {" "}followers
                    </Text>
                </Text>
            </Flex>
        </Flex>
    );
}

export default InfluencerId;
