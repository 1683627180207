import {
    Button,
    Flex,
    IconButton,
    Text,
    Heading,
    VStack,
    Box,
    useDisclosure,
} from "@chakra-ui/react";
import GenericCard from "../Collection-flow/GenericCard";
import { pixels } from "../../theme";
import TrMapLeaflet from "../TrMapLeaflet";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import Carousel from 'react-multi-carousel';
import TopHighlightItem from "../TopHighlights/TopHighlightItem";

const CustomLeftArrow = ({ onClick }: any) => {
	return (
		<IconButton
			minW={'30px !important'}
			h='30px'
			borderRadius={'50%'}
			top={'15%'}
			left={'-18px'}
			transform={'translateY(-50%)'}
			position={'absolute'}
			aria-label='previous-button'
			onClick={onClick}
			icon={
				<ChevronLeftIcon
					width={pixels['13pixels']}
					height={pixels['13pixels']}
				/>
			}
			color={'white'}
			backgroundColor='rgba(57, 56, 59, 0.93)'
		/>
	);
};
interface TopHighlightItemProps {
	previewImage: string;
	city: string;
	country: string;
	url: string;
	best?: any;

	best_value: [string];
	title: string;
	description: string;
	tags?: any;
	entity_best_time_to_visit?: any;
	vibe?: any;
}
const CustomRightArrow = ({ onClick }: any) => {
	return (
		<IconButton
			minW={'30px !important'}
			h='30px'
			borderRadius={'50%'}
			top={'15%'}
			right={0}
			transform={'translateY(-50%)'}
			position={'absolute'}
			aria-label='next-button'
			onClick={onClick}
			icon={
				<ChevronRightIcon
					width={pixels['13pixels']}
					height={pixels['13pixels']}
				/>
			}
			color={'white'}
			backgroundColor='rgba(57, 56, 59, 0.93)'
		/>
	);
};

const InfLocationDetail: React.FC = () => {
    const { currentVideoIndex, searchDetail }: any = useContext(AppContext);

    const {
        onOpen: onOpenDetailsModal,
    } = useDisclosure();

    const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: 3,
			partialVisibilityGutter: 100,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 3,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 2,
			paritialVisibilityGutter: 100,
		},
		mobile: {
			breakpoint: { max: 464, min: 464 },
			items: 3,
		},
	};

    return (
        <Flex flexDir={"column"}>

            <Flex
                justify="space-between"
                align="center"
                maxH="68px"
                w={"100%"}
                py="16px"
                visibility={["visible", "visible", "hidden"]}
                mt={1}
            >
                <IconButton
                    aria-label="Back"
                    icon={<img src="/icons/Back_Icon_White.svg" alt="back" />}
                    colorScheme="gray"
                    size="lg"
                    variant={"link"}
                    onClick={() => { }}
                />

                <Button bg="inherit" variant="link">
                    <img src={"/icons/share3.svg"} alt="share Icon" />
                </Button>
            </Flex>

            <Flex justifyContent={"space-between"}>
                <VStack spacing={"8px"} alignItems={"flex-start"} ml={"14px"}>
                    <Heading
                        fontWeight={700}
                        fontSize={"24px"}
                        lineHeight={"29px"}
                        bgGradient="linear-gradient(180deg, rgba(255, 255, 255, 0.93) 53.49%, rgba(57, 55, 55, 0.93) 95.69%)"
                        bgClip={"text"}
                    >
                        Top 5 secret beaches in Nusa Penida
                    </Heading>
                    <Text
                        fontFamily="Poppins"
                        fontSize="13px"
                        fontWeight="500"
                        lineHeight="16.95px"
                        letterSpacing="0.30000001192092896px"
                        textAlign="left"
                        color={'#9D9D9D'}>
                        Bali,Indonesia
                    </Text>
                    <Box mt={-2}>
                        <GenericCard
                            subtitle="Nusa Penida is a stunning island known for its secluded, untouched beaches, offering serene escapes with dramatic cliffs and pristine waters. ..more" />
                    </Box>

                    {/* map section */}

                    <Box
                        borderRadius={pixels['24pixels']}
                        position={'relative'}
                        h={'130.59px'}
                        mt={3}
                        w={['full', '100%', '328.07px']}>
                        <TrMapLeaflet
                            marks={[{
                                latitude: -8.727807,
                                longitude: 115.544426
                            }
                            ]} />
                    </Box>


                    <Box borderBottom={'1px solid #FFFFFF21'} mt={4} mb={4} w={'-webkit-fill-available'} />
                    {/* Video Highlights */}
                </VStack>
            </Flex>
            <Heading
            fontFamily= "Poppins"
            fontSize= "20px"
            fontWeight= "500"
            lineHeight= "13px"
            letterSpacing= "-0.30000001192092896px"
            textAlign= "left"
            color={'#FFFFFFA1'}
            mb={'32px'}
            >Video Highlights</Heading>
            <Flex position={'relative'} height={'700px'} w={'100%'}>
					{searchDetail && searchDetail?.entities && (
						<Carousel
							autoPlay={false}
							infinite={true}
							pauseOnHover={true}
							className='slider-highlight'
							slidesToSlide={3}
							responsive={responsive}
							customLeftArrow={<CustomLeftArrow />}
							customRightArrow={<CustomRightArrow />}>
							{searchDetail &&
							searchDetail?.entities?.length > 0 &&
							searchDetail?.entities[currentVideoIndex] &&
							searchDetail?.entities[currentVideoIndex].highlights
								? searchDetail?.entities[currentVideoIndex].highlights?.map(
										(item: TopHighlightItemProps, index: number) => {
											return (
												<TopHighlightItem
													key={index}
													previewImage={item.previewImage}
													city={searchDetail.city}
													country={searchDetail?.user_query_entity?.country}
													url={item.url}
													title={item.title}
													best_title={Object.keys(item?.best)[0]}
													best_value={Object.values(item?.best)[0]}
													description={item.description}
													vibe={item?.vibe}
													best_time_to_visit={item?.entity_best_time_to_visit}
													tags={item?.tags}
													highLightIndex={index}
													onOpenDetailsModal={onOpenDetailsModal}
												/>
											);
										},
								  )
								: []}
						</Carousel>
					)}
				</Flex>
        </Flex>
    );
};
export default InfLocationDetail;
