// HomePageDesktop.tsx

import { Flex } from "@chakra-ui/react";
import BackgroundText from "./SearchComponents/BackgroundText";
import SearchBox from "./SearchBox"; // Import the new SearchBox component
import { useContext, useEffect , useState } from "react";
import useAxios from "../hooks/useAxios";
import { AppContext } from "../context/AppContext";
import ItineraryFooter from "./CreateItinary/ItineraryFooter";

const HomePageDesktop = () => {
    const context: any = useContext(AppContext);
    const { fetchData } = useAxios();
    const { setLikeEntities, setLikeSearches } = context;
    const [once , setOnce] = useState(1)

    useEffect(() => {


    const getUserLikeSearches = async () => {
      try {
          const { data } = await fetchData({
              method: "POST",
              url: `user/getUserLikeSearches`,
              options: {
                  data: { token: localStorage.getItem("token") },
                  headers: { "Content-Type": "application/json" },
              },
              auth: false,
          });

          const like_searches_data = data["data"];
          const like_searches = like_searches_data["like_searches"];
          setLikeSearches(like_searches);
          return like_searches;
      } catch (error) {
          console.error("Error fetching user like searches:", error);
          return [];
      }
  };

  const getUserLikeEntities = async () => {
      try {
          const { data } = await fetchData({
              method: "POST",
              url: `user/getUserLikeEntities`,
              options: {
                  data: { token: localStorage.getItem("token") },
                  headers: { "Content-Type": "application/json" },
              },
              auth: false,
          });

          const like_entities_data = data["data"];
          const like_entities = like_entities_data["like_entities"];
          setLikeEntities(like_entities);
          return like_entities;
      } catch (error) {
          console.error("Error fetching user like entities:", error);
          return [];
      }
  };


        if(localStorage.getItem('token') && once){
          getUserLikeSearches();
          getUserLikeEntities();
          setOnce(0)
        }
        
    },[fetchData , setLikeEntities , setLikeSearches  , once]);

    return (
        <Flex
            m={0}
            p={0}
            h="100vh"
            w="100%"
            position={"fixed"}
            overflow={"hidden"}
            background={{
                base: "url(/images/mobile-index-background.png), linear-gradient(118deg, #272529 5.68%, #111012 59.38%)",
                lg: "url(/images/background-image.png), linear-gradient(118deg, #272529 5.68%, #111012 59.38%)",
            }}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDir={"column"}
            backgroundSize="cover !important"
            backgroundRepeat="no-repeat !important"
        >
            <Flex
                top={{ base: "30%", lg: "39%" }}
                position={"absolute"}
                sx={{
                    position: "absolute",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    w: "max-content",
                }}
            >
                <BackgroundText />
            </Flex>
            <SearchBox /> {/* Use the new SearchBox component */}
            <ItineraryFooter />
        </Flex>
    );
};

export default HomePageDesktop;
