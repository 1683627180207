import {  Flex, VStack } from '@chakra-ui/react'
import { Box, Text, Heading } from '@chakra-ui/react';
import Header from '../components/TravelAdvisor/Header';
import ResortCard from '../components/TravelAdvisor/ResortCard';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import InputField from '../components/TravelAdvisor/InputField';
import { useNavigate } from 'react-router-dom';

const TravelAdvisor: React.FC = () => {
  const navigate=useNavigate();
  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    centerMode: true,
    // centerPadding: '-20px',
    arrows: true,
    autoplay: true,
    autoplaySpeed: 4000,
  };
   // 

  return (
    <Box
      width="100%"
      height="100vh"
      backgroundImage='/images/bg_images/Bg_4.png'
      position='relative'
      overflow='hidden'
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
    >
      <Box
        mb={'100px'}>
        <Header
          backIconSrc="/icons/Back_Icon_White.svg"
          chatIconSrc="/icons/chat_filled copy.svg"
          backNavigationPath="/home"
          chatButtonOnClick={() => { navigate('/home/savedConversation') }}
          bgColor="#2F2F32"
          borderColor="linear-gradient(135deg, #FF7E5F, #FEB47B)"
        />
        <Flex mb={'36px'}
          direction='column'
          align={"center"}>
          <Text color={"#FFFFFF"}
            fontSize={"23px"}
            fontWeight={700}>
            Your personal
          </Text>
          <Text
            bgGradient="linear-gradient(90deg, #9BA1F2 0%, #F9EEC5 100%)"
            bgClip={"text"}
            fontSize={"23px"}
            fontWeight={700}>
            Travel Advisor
          </Text>
          <Text fontWeight={500} fontSize={"11px"} color={"#FFFFFFA1"} mt={"2px"}>
            Start your journey with our specialised advisors
          </Text>
        </Flex>
        <Slider {...settings} >
          <ResortCard
            title="Resort Rendezvous"
            subtitle="specialises in"
            imageSrc="/images/f1.png"
            question1="Best staycation getaways near me"
            question2="Best beach resorts in Thailand"
          />
          <ResortCard
            title="Family vacation with kids"
            subtitle="specialises in"
            imageSrc="/images/f2.png"
            question1="Must-Visit Theme Parks for Kids"
            question2="Toddler friendly trip to Italy"
          />
          <ResortCard
            title="Romantic Retreats"
            subtitle="specialises in"
            imageSrc="/images/f3.png"
            question1="Trending Honeymoon destinations"
            question2="weekend getaways for couples"
          />
        </Slider>
        <Flex bg={'#FFFFFF'}
          zIndex={100000000000}
          direction={'column'}
          position="fixed"
          pt={'35px'}
          pb={'45px'}
          borderRadius="32px 32px 0px 0px"
          bottom="0"
          w={'full'}>
          <VStack align={'center'}
            px={'31px'}
          >
            <Heading
              color={"#16161A"}
              fontSize={'23px'}
              letterSpacing={"-0.53px"}
              mb={'21px'}
            >
              Design your own trip!
            </Heading>
            <Box>
            <InputField
              placeholder='Ask here...' />
              </Box>

          </VStack>
        </Flex>
      </Box>
    </Box>

  );
}
export default TravelAdvisor;