import React, { useEffect, useState } from 'react';
import {
	MapContainer,
	TileLayer,
	Marker,
	Popup,
	ZoomControl,
	useMap,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import 'leaflet-fullscreen'; // Import the JavaScript part of the plugin

// Fix for default marker icon issue with Webpack
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import { pixels } from '../theme';
import { Avatar, Box, Text } from '@chakra-ui/react';

const DefaultIcon = L.icon({
	iconUrl: markerIcon,
	iconRetinaUrl: markerIcon2x,
	shadowUrl: markerShadow,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

interface Mark {
	latitude: number;
	longitude: number;

	title?: string;

	previewImage?: string;
}

interface MapComponentProps {
	marks?: Mark[];
}

const FullscreenControl = ({
	setFullscreen,
}: {
	setFullscreen: (val: boolean) => void;
}) => {
	const map = useMap();

	useEffect(() => {
		if (map) {
			const onFullscreenChange = () => {
				//@ts-ignore
				const isFullscreen = map.isFullscreen();
				setFullscreen(isFullscreen);
			};

			// Check if the fullscreen control is already added
			//@ts-ignore
			const fullscreenControlExists = map._controlCorners.bottomright //@ts-ignore
				.querySelector('.leaflet-control-fullscreen');

			if (!fullscreenControlExists) {
				//@ts-ignore
				L.control
					//@ts-ignore
					.fullscreen({
						position: 'bottomright',
						title: 'Expand to fullscreen',
						titleCancel: 'Exit fullscreen',
					})
					.addTo(map);
			}

			map.on('fullscreenchange', onFullscreenChange);

			// Cleanup event listener on unmount
			return () => {
				map.off('fullscreenchange', onFullscreenChange);
			};
		}
	}, [map, setFullscreen]);

	return null;
};

const MapComponent: React.FC<MapComponentProps> = ({ marks }) => {
	const [currentMark, setCurrentMark] = useState(0);
	const [isFullscreen, setIsFullscreen] = useState(false); // Track fullscreen state
	// Provide default center if marks is undefined or empty
	const defaultCenter = [0, 0]; // Change to appropriate default values if needed

	// Function to re-center the map when a marker is clicked
	const CenterMapToMarker = () => {
		const map = useMap();
		useEffect(() => {
			if (marks && marks.length > 0) {
				const current = marks[currentMark];
				map.setView([current?.latitude, current?.longitude], map.getZoom(), {
					animate: true,
				});
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [currentMark, marks, map]);
		return null;
	};

	return (
		<>
			{typeof currentMark === 'number' && (
				<MapContainer
					id='map'
					//@ts-ignore
					center={
						marks && marks.length > 0
							? [marks[currentMark]?.latitude, marks[currentMark]?.longitude]
							: defaultCenter
					}
					zoom={8}
					style={{
						position: 'relative',
						borderRadius: pixels['20pixels'],
						height: '100%',
						width: '100%',
					}}
					zoomControl={false} // Disable default zoom controls
				>
					<TileLayer
						url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
						//@ts-ignore
						options={{
							attribution:
								'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
						}}
					/>
					{/* Show only the marker for the current selected mark */}
					{marks &&
						marks.length > 0 &&
						marks?.map((mark, index) => (
							<Marker key={index} position={[mark?.latitude, mark?.longitude]}>
								<Popup>
									{/* A pretty CSS3 popup. <br /> Easily customizable. */}
								</Popup>
							</Marker>
						))}

					{/* Re-center map on marker change */}
					<CenterMapToMarker />

					<ZoomControl position='topleft' />
					<FullscreenControl setFullscreen={setIsFullscreen} />

					{/* Conditionally render the component only in fullscreen mode */}
					{isFullscreen && marks && marks.length > 0 && (
						<Box
							display={'flex'}
							flexDir={'row'}
							gap={'20px'}
							zIndex={9999999999999}
							position={'absolute'}
							left={10}
							bottom={10}>
							{marks.map((mark, index) => (
								<Box
									key={index}
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										setCurrentMark(index); // Update the current marker index
									}}
									display={'flex'}
									flexDir={'row'}
									background={
										index === currentMark ? '#fff' : 'rgba(31, 29, 33, 0.33)'
									}
									width={'221px'}
									height={'60px'}
									overflow={'hidden'}
									border='2px solid #fff'
									borderRadius={'12px'}
									zIndex={99999999999999999}
									cursor='pointer'>
									<Avatar
										w={'30%'}
										height={'100%'}
										borderRadius={'0'}
										src={mark?.previewImage}
										name={mark?.title}
									/>
									<Box
										p='3px'
										display={'flex'}
										justifyContent={'center'}
										alignItems={'center'}
										w='70%'
										height={'100%'}>
										<Text
											my='auto'
											fontSize={'16px'}
											color={index === currentMark ? '#000' : '#fff'}>
											{mark?.title}
										</Text>
									</Box>
								</Box>
							))}
						</Box>
					)}
				</MapContainer>
			)}
		</>
	);
};

export default MapComponent;
