import {
	Box,
	Flex,
	Text,
	Heading,
	SimpleGrid,
	IconButton,
} from '@chakra-ui/react';
import TopHighlightItem from './TopHighlightItem';
import { pixels } from '../../theme';
import { useContext } from 'react';
import Carousel from 'react-multi-carousel';
import { AppContext } from '../../context/AppContext';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

interface TopHighlightItemProps {
	previewImage: string;
	city: string;
	country: string;
	url: string;
	best?: any;

	best_value: [string];
	title: string;
	description: string;
	tags?: any;
	entity_best_time_to_visit?: any;
	vibe?: any;

	location?: { lat: number; lng: number };
}

interface TopHighlightsProps {
	onOpenDetailsModal: () => void;
}

const CustomLeftArrow = ({ onClick }: any) => {
	return (
		<IconButton
			minW={'30px !important'}
			h='30px'
			borderRadius={'50%'}
			top={'15%'}
			left={'-18px'}
			transform={'translateY(-50%)'}
			position={'absolute'}
			aria-label='previous-button'
			onClick={onClick}
			icon={
				<ChevronLeftIcon
					width={pixels['13pixels']}
					height={pixels['13pixels']}
				/>
			}
			color={'white'}
			backgroundColor='rgba(57, 56, 59, 0.93)'
		/>
	);
};

const CustomRightArrow = ({ onClick }: any) => {
	return (
		<IconButton
			minW={'30px !important'}
			h='30px'
			borderRadius={'50%'}
			top={'15%'}
			right={{ 'base': 0, 'lg': '-19px', '2xl': '-19px' }}
			transform={'translateY(-50%)'}
			position={'absolute'}
			aria-label='next-button'
			onClick={onClick}
			icon={
				<ChevronRightIcon
					width={pixels['13pixels']}
					height={pixels['13pixels']}
				/>
			}
			color={'white'}
			backgroundColor='rgba(57, 56, 59, 0.93)'
		/>
	);
};

const TopHighlights = ({ onOpenDetailsModal }: TopHighlightsProps) => {
	const { currentVideoIndex, searchDetail }: any = useContext(AppContext);
	console.log('search detail', searchDetail);
	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: 3,
			partialVisibilityGutter: 100,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 3,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 2,
			paritialVisibilityGutter: 100,
		},
		mobile: {
			breakpoint: { max: 464, min: 464 },
			items: 3,
		},
	};

	return (
		<Box px={{ lg: `20px` }} overflow={'clip'}>
			<Box
				// pl='18px'
				// overflow={'hidden'}
				minH={'410px'}
				display={{ base: 'none', md: 'block', lg: 'block' }}>
				<Text
					sx={{
						color: 'rgba(255, 255, 255, 0.83)',
						fontFamily: 'Poppins',
						fontSize: { 'base': '19.5px', 'lg': '17.5px', '2xl': '24px' },
						fontStyle: 'normal',
						fontWeight: '700',
						mb: { 'base': pixels['12pixels'], '2xl': pixels['30pixels'] },
					}}>
					Top highlights in this video
				</Text>
				<Flex position={'relative'} height={'700px'} w={'100%'}>
					{searchDetail && searchDetail?.entities && (
						<Carousel
							autoPlay={false}
							infinite={false}
							pauseOnHover={true}
							className='slider-highlight'
							slidesToSlide={1}
							responsive={responsive}
							customLeftArrow={<CustomLeftArrow />}
							customRightArrow={<CustomRightArrow />}>
							{searchDetail &&
							searchDetail?.entities?.length > 0 &&
							searchDetail?.entities[currentVideoIndex] &&
							searchDetail?.entities[currentVideoIndex].highlights
								? searchDetail?.entities[currentVideoIndex].highlights?.map(
										(item: TopHighlightItemProps, index: number) => {
											return (
												<TopHighlightItem
													key={index}
													previewImage={item.previewImage}
													city={searchDetail.city}
													country={searchDetail?.user_query_entity?.country}
													url={item.url}
													title={item.title}
													best_title={
														item?.best ? Object.keys(item?.best)[0] : ''
													}
													best_value={
														item?.best ? Object.values(item?.best)[0] : ''
													}
													description={item.description}
													location={item?.location}
													vibe={item?.vibe}
													best_time_to_visit={item?.entity_best_time_to_visit}
													tags={item?.tags}
													highLightIndex={index}
													onOpenDetailsModal={onOpenDetailsModal}
												/>
											);
										},
								  )
								: []}
						</Carousel>
					)}
				</Flex>
			</Box>

			{/* mobile */}
			<Box px={'20px'} display={{ base: 'block', md: 'none', lg: 'none' }}>
				<Heading
					fontSize={'x-large'}
					color={'rgb(182 182 182)'}
					sx={{ fontFamily: 'Poppins' }}
					mb={'10px'}>
					Video highlights
				</Heading>
				<SimpleGrid columns={2} spacing={5}>
					{searchDetail &&
					searchDetail?.entities?.length > 0 &&
					searchDetail?.entities[currentVideoIndex] &&
					searchDetail?.entities[currentVideoIndex].highlights
						? searchDetail?.entities[currentVideoIndex].highlights?.map(
								(item: any, index: number) => {
									return (
										<TopHighlightItem
											key={index}
											previewImage={item.previewImage}
											location={item?.location}
											city={searchDetail.city}
											country={searchDetail?.user_query_entity?.country}
											url={item.url}
											title={item.title}
											best_title={item?.best ? Object.keys(item?.best)[0] : ''}
											best_value={
												item?.best ? Object.values(item?.best)[0] : ''
											}
											vibe={item?.vibe}
											best_time_to_visit={item?.entity_best_time_to_visit}
											description={item.description}
											tags={item?.tags}
											highLightIndex={index}
											onOpenDetailsModal={onOpenDetailsModal}
										/>
									);
								},
						  )
						: ''}
				</SimpleGrid>
			</Box>
		</Box>
	);
};

export default TopHighlights;
