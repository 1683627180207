import { Input, InputGroup, InputRightElement,Image } from "@chakra-ui/react";

import React from "react";

const SearchBar: React.FC = () => {
    return (
        <InputGroup my={4} w={'92vw'}  >
            <Input
                py={'25px'}
                placeholder="Search"
                _placeholder={{ color: "#FFFFFF" }}
                bg="#242127" color="#FFFFFF"
                borderRadius="12px"
                border={'1px solid #FFFFFF12'}
                h={'48px'} pl={5} />
            <InputRightElement py={'25px'} pointerEvents="none" mt={'1px'} children={<Image boxSize={'18px'} src={'/icons/search2.svg'} alt="search" />} />
        </InputGroup>
    );
};
export default SearchBar;
