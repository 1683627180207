import {
  Box,
  Flex,
  Text,
  Image,
  VStack,
  Heading,
  Button,
  HStack,
  SkeletonText,
} from "@chakra-ui/react";
import Header from "./Header";
import InputField from "./InputField";
import { useContext, useEffect, useState, useRef } from "react";
import { AppContext } from "../../context/AppContext";
import ChatCard from "./ChatCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";

const ChatBox: React.FC = () => {
  const navigate = useNavigate();
  const context: any = useContext(AppContext);
  const { chatEntries, setChatEntries, isLoading } = context; // Global chat entries from context
  const [clickedIndex, setClickedIndex] = useState<number | null>(null);

  const [showCallingModal, setShowCallingModal] = useState(false); // State to handle the modal visibility

  const bottomRef = useRef<HTMLDivElement>(null); // Ref to scroll to the bottom
  const settings = {
    dots: false,
    infinite: false,
    speed: 200,
    centerMode: true,
    slidesToShow: 1.2,
    centerPadding: "15px",
    arrows: false,
    autoplay: false,
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setChatEntries([
        {
          via: "server",
          clicable: false,
          message: "I can call on your behalf and check with the restaurant. ",
        },
        {
          via: "server",
          clicable: true,
          message: "Yes please proceed",
        },
        {
          via: "server",
          clicable: true,
          message: "No, thanks",
        },
      ]);
    }, 5000);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, [setChatEntries]);

  useEffect(() => {
    // Scroll to bottom when chatEntries change
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatEntries]);

  function handleClick(message: string, index: number): void {
    setChatEntries((prevEntries: any) => [
      ...prevEntries,
      {
        via: "user",
        clickable: false,
        message: message,
      },
    ]);
    setClickedIndex(index);
    if (message === "Yes please proceed") {
      setTimeout(() => {
        setChatEntries((prevEntries: any) => [
          ...prevEntries,
          {
            via: "user",
            clickable: false,
            message:
              "Also, make a reservation for 2 people for 1pm, if available",
            time: "7:30 PM, 1st April",
          },
        ]);
        setTimeout(() => {
          setShowCallingModal(true); // Show the modal when the component mounts
        }, 5000);

        setTimeout(() => {
          setChatEntries((prevEntries: any) => [
            ...prevEntries,
            {
              via: "server",
              clickable: false,
              message:
                "The reservation is available for the requested date. I have booked it under your name for two people.",
              time: "7:30 PM, 1st April",
            },
          ]);
          setShowCallingModal(false); // Hide the modal after 5 seconds
        }, 10000);
      }, 3000);
    }
  }

  return (
    <Flex
      bg={"#FFFFFF"}
      direction={"column"}
      minH="100vh"
      w="100vw"
      position="relative"
    >
      {/* Header remains fixed */}
      <Header
        backIconSrc="/icons/arrow_back.svg"
        chatIconSrc="/icons/chat_filled.svg"
        addIconSrc="/icons/plus_outline.svg"
        backNavigationPath="/home"
        chatButtonOnClick={() => {}}
        bgColor="#2F2F320D"
        borderColor="linear-gradient(135deg, #FF7E5F, #FEB47B)"
      />

      {/* Chat Topic Bar */}
      <Flex
        borderTop={"1px solid #3A3C4221"}
        mt={"5px"}
        pt={"8px"}
        pb={"7px"}
        pl={"18px"}
        boxShadow={"0px 7px 7px 0px #00000008"}
      >
        <Text
          fontSize={"11px"}
          fontFamily={"500"}
          letterSpacing={"-0.47px"}
          color={"#16181BA1"}
        >
          Best michelin star restaurants in Bangkok
        </Text>
      </Flex>

      <Box
        flex={1}
        overflowY="auto"
        position={"relative"}
        px={4}
        py={4}
        mb={"10px"}
      >
        {/* Example Message */}
        <Flex direction={"column"}>
          <Box
            bg={"#E8E9FF"}
            p={"10px"}
            borderRadius={"8px"}
            maxW={"227px"}
            mt={"30px"}
          >
            <Text color={"#16181B"} fontSize={"14px"} fontWeight={600}>
              Here are our recommendations based on your preference
            </Text>
          </Box>
          <Text
            color={"#B5B5B5"}
            fontSize={"11px"}
            mt={2}
            letterSpacing={"-0.47px"}
          >
            7:30 PM, 1st April
          </Text>
        </Flex>

        {/* Slider */}
        <Box pt="12px" pb={"12px"} h={"auto"}>
          <Slider {...settings}>
            <ChatCard
              imageSrc="/images/dish1.jpg"
              altText="dish1"
              title="Bangkok Bold Kitchen"
              subtitle="Khwaeng Silom"
              buttonText="View Details"
              onCardClick={() => navigate("/home/stories")}
            />
            <ChatCard
              imageSrc="/images/dish2.png"
              altText="dish2"
              title="Haoma Bangkok"
              subtitle="Khwaeng Silom"
              buttonText="View Details"
              onButtonClick={() => {}}
            />
          </Slider>
        </Box>
        <Flex justifyContent={"flex-end"} mt={"28px"} pr={"12px"}>
          <Box p={"7px"} bg={"#E0F0EA"} borderRadius={"12px"} maxW={"260px"}>
            <HStack spacing={0} borderRadius={"9px"} bg={"#F9FAFC"} h="72px">
              <Image
                src={"/images/dish1.jpg"}
                alt={"dish1"}
                borderRadius={"12px 0px 0px 12px"}
                objectFit={"cover"}
                maxH={"72px"}
              />
              <Box py={"32px"}>
                <VStack
                  spacing={0}
                  alignItems={"flex-start"}
                  ml={"12px"}
                  mt={0}
                >
                  <Heading
                    fontSize="12px"
                    letterSpacing={"-0.5px"}
                    fontWeight={700}
                    color={"#424242"}
                  >
                    Bangkok Bold Kitchen
                  </Heading>
                  <Heading
                    fontSize={"11px"}
                    color={"#424242"}
                    letterSpacing={"-0.5px"}
                    fontWeight={400}
                  >
                    Khwaeng Silom
                  </Heading>
                </VStack>
              </Box>
            </HStack>
            <Text
              px={"12px"}
              py={"6px"}
              color={"#16181B"}
              fontSize={"14px"}
              fontWeight="600"
              letterSpacing={"-0.47px"}
            >
              Does this restaurant do a New year’s brunch?
            </Text>
          </Box>
        </Flex>

        {/* Display clickable chat entries */}
        <Box mt={"20px"}>
          {chatEntries.map(
            (
              entry: {
                message: string;
                via: string;
                clicable: boolean;
                time: string;
              },
              index: number
            ) => (
              <Flex
                justifyContent={
                  entry.via === "user" ? "flex-end" : "flex-start"
                }
                key={index} // Added key here
                mb={2} // Margin between entries
              >
               {((entry.message==="..." && isLoading)?(<SkeletonText height="40px" width="40vw" borderRadius="8px" p={"10px"} noOfLines={3}/>)
                : <Flex
                  direction={"column"}
                  maxW={"227px"}
                  mr={"12px"}
                  alignItems={entry.via === "user" ? "flex-end" : "flex-start"}
                  cursor={entry.clicable ? "pointer" : "default"}
                >
                  <Box
                    borderRadius={"8px"}
                    bg={
                      !entry.clicable
                        ? entry.via === "server"
                          ? "#E8E9FF"
                          : "#E0F0EA"
                        : "none"
                    }
                  >
                    {!entry.clicable && (
                      <Text
                        color={"#16181B"}
                        fontSize={"14px"}
                        p={"10px"}
                        fontWeight={600}
                      >
                        {entry.message}
                      </Text>
                    )}
                  </Box>
                  <Text
                    color={"#B5B5B5"}
                    fontSize={"11px"}
                    mt={1}
                    letterSpacing={"-0.47px"}
                  >
                    {entry.time}
                  </Text>
                  {entry.clicable && (
                    <Button
                      mt={"-7px"}
                      color={clickedIndex === index ? "#FFFFFF" : "#16181B"} // Change text color on click
                      bg={clickedIndex === index ? "#404040" : "transparent"} // Change background color on click
                      variant={clickedIndex === index ? "solid" : "outline"} // Change variant on click
                      fontSize={"14px"}
                      borderRadius={"8px"}
                      fontWeight={600}
                      onClick={() => handleClick(entry.message, index)}
                    >
                      {entry.message}
                    </Button>
                  )}
                </Flex>)}
              </Flex>
            )
          )}
          {/* Scroll reference element */}
          <div ref={bottomRef} />
        </Box>
      </Box>

      {/* Input Field */}
      <Box
        position={"sticky"}
        bottom={"10px"}
        bg={"white"}
        px={"31px"}
        py={3}
        w={"full"}
      >
        <InputField placeholder="Type here..." />
      </Box>

      {/* Conditional Calling Modal */}
      {showCallingModal && (
        <Flex
          position="fixed"
          top="0"
          left="0"
          w="100vw"
          h="100vh"
          justifyContent="center"
          alignItems="center"
          bg="#050002D4"
          zIndex={10000}
        >
          <VStack spacing={"31px"}>
            <Image src="/icons/call.png" />
            <VStack spacing={1}>
              <Text
                color={"#FFFFFFD4"}
                fontSize={"23px"}
                fontWeight={500}
                lineHeight={"32px"}
                letterSpacing={"-0.81px"}
              >
                Calling restaurant...
              </Text>
              <Heading
                color={"white"}
                fontSize={"30px"}
                fontWeight={600}
                fontFamily={"Poppins"}
                lineHeight={"53px"}
                letterSpacing={"-1.33px"}
              >
                Bangkok Bold Kitchen
              </Heading>
            </VStack>
            <Text
              color={"#FFFFFFA1"}
              textAlign={"center"}
              fontSize={"16px"}
              fontWeight={400}
              lineHeight={"23px"}
              letterSpacing={"-0.81px"}
            >
              Please wait for a few minutes for us to get back with your request
            </Text>
          </VStack>
        </Flex>
      )}
    </Flex>
  );
};

export default ChatBox;
