import React, { useContext, useEffect } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import SearchBox from '../components/SearchBox';
import { pixels } from '../theme';
import CardSlider from '../components/CardSlider';
import HomePageFooter from '../components/HomePageFooter';
import SearchPageSection from './SearchPageSection';
import SideBar from '../components/SideBar';
import { AppContext } from '../context/AppContext';

const SearchPage: React.FC = () => {
	const { setIp }: any = useContext(AppContext);

	useEffect(
		() => {
			const fetchIp = async () => {
				try {
					const response = await fetch('https://api.ipify.org?format=json');
					const data = await response.json();
					setIp(data.ip);
				} catch (error) {
					console.error('Error fetching the IP address:', error);
				}
			};

			fetchIp();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);
	return (
		<Flex
			overflow={'auto'}
			flexDir={'column'}
			alignItems={'center'}
			bg='linear-gradient(118deg, #272529 5.68%, #111012 59.38%)'
			pt={{
				'base': 0,
				'lg': `65px`,
				'2xl': pixels['65pixels'],
			}}
			pb={pixels['150pixels']}
			w='100%'>
			<SearchBox suggestionBackground='black' />
			<Flex
				// display={'flex'}
				// justifyContent={'center'}
				// alignItems={'center'}
				flexDir={'column'}
				mt={{ 'base': 0, 'lg': `calc(90px * 0.8)`, '2xl': '85px' }}
				maxW={{
					'base': '100%',
					'md': pixels['700pixels'],
					'lg': '900px',
					'2xl': pixels['1100pixels'],
				}}
				gap={pixels['30pixels']}
				mx='auto'>
				<Box id='section1' display={'flex'} justifyContent={'center'}>
					<SearchPageSection />
				</Box>

				{/* Ref assigned to section 2 */}
				<Box
					mt={'150px'}
					id='section2'
					px={{ base: pixels['10pixels'], lg: 0 }}>
					<Box display={{ base: 'none', lg: 'block' }}>
						<CardSlider />
					</Box>
					<HomePageFooter />
					<SideBar />
				</Box>
			</Flex>
		</Flex>
	);
};

export default SearchPage;
