import { VStack, Text, Button, Flex } from "@chakra-ui/react";

interface DetailProps {
  label: string;        
  value: string;       
  buttonText: string;  
  onEditClick: () => void; 
}

const Detail: React.FC<DetailProps> = ({ label, value, buttonText, onEditClick }) => {
  return (
    <VStack
      borderRadius="12px"
      mx="30px"
      py="18px"
      px="30px"
      w="360px"
      mb={'10px'}
      border="1px solid #FFFFFF12"
      alignItems="space-between"
      maxW="100vw"
    >
      <Flex justifyContent="space-between">
        <Flex flexDir="column">
          <Text
            fontFamily="Poppins"
            fontSize="13px"
            fontWeight="500"
            lineHeight="17px"
            letterSpacing="-0.465063214302063px"
            textAlign="left"
            color="#FFFFFF54"
          >
            {label} {/* Customizable label */}
          </Text>
          <Text
            fontFamily="Poppins"
            fontSize="16px"
            fontWeight="500"
            lineHeight="17px"
            letterSpacing="-0.47px"
            textAlign="left"
            color="#FFFFFFED"
          >
            {value} {/* Customizable text content */}
          </Text>
        </Flex>
        <Button
          w="23px"
          h="17px"
          variant="link"
          fontFamily="Poppins"
          fontSize="13px"
          fontWeight="500"
          lineHeight="17px"
          letterSpacing="-0.465063214302063px"
          textAlign="left"
          color="#AE7CFF"
          onClick={onEditClick} 
        >
          {buttonText} {/* Customizable button text */}
        </Button>
      </Flex>
    </VStack>
  );
};

export default Detail;
