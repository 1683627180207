import React from 'react';
import { Card, Heading, HStack, VStack, Button, Image, Box } from "@chakra-ui/react";

// Define the types of props the component will accept
interface ChatCardProps {
  imageSrc: string;
  altText: string;
  title: string;
  subtitle: string;
  buttonText: string;
  onButtonClick?: () => void; // Optional callback for button click
  onCardClick?: () => void;
}

const ChatCard: React.FC<ChatCardProps> = ({
  imageSrc,
  altText,
  title,
  subtitle,
  buttonText,
  onButtonClick,
  onCardClick
}) => {
  return (
    <Card borderRadius={'18px'} p={0} w={'260px'} h={'285px'} border={'1px solid #E5E5E5'} onClick={onCardClick}>
      {/* Conditionally render a placeholder box if no image source is provided */}
      {imageSrc ? (
        <Image
          src={imageSrc}
          alt={altText}
          borderRadius={'18px'}
          objectFit={'cover'}
          maxH={'210px'}
        />
      ) : (
        <Box
          borderRadius={'18px'}
          bgColor={'gray.200'}
          w={'100%'}
          h={'210px'}
        />
      )}

      <HStack spacing='12px' mt={'12px'} alignItems={'flex-start'} pb={'22px'}>
        <VStack spacing={0} alignItems={'flex-start'} ml={'12px'} mt={0}>
          {/* Render a placeholder if title is not provided */}
          {title ? (
            <Heading size='12px' letterSpacing={'-0.5px'} fontWeight={700} color={'#424242'}>
              {title}
            </Heading>
          ) : (
            <Box w={'100px'} h={'12px'} bgColor={'gray.200'} borderRadius={'4px'} />
          )}

          {/* Render a placeholder if subtitle is not provided */}
          {subtitle ? (
            <Heading size={'11px'} color={'#424242'} letterSpacing={'-0.5px'} fontWeight={400}>
              {subtitle}
            </Heading>
          ) : (
            <Box w={'80px'} h={'11px'} bgColor={'gray.200'} borderRadius={'4px'} />
          )}
        </VStack>

        {/* Render a placeholder button if buttonText is not provided */}
        {buttonText ? (
          <Button
            mt={1}
            variant={'link'}
            color={'#7539D7'}
            fontWeight={700}
            fontSize={'12px'}
            onClick={onButtonClick}
          >
            {buttonText}
          </Button>
        ) : (
          <Box w={'60px'} h={'12px'} bgColor={'gray.200'} borderRadius={'4px'} />
        )}
      </HStack>
    </Card>
  );
}

export default ChatCard;
