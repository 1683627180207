import { Box, VStack } from "@chakra-ui/react";
import Header from "./Header";
import TabNavigation from "./TabNavigation";
import ItineraryFooter from "../CreateItinary/ItineraryFooter";

const LandingPage: React.FC = () => {
    return (
        <>
            <VStack bg='black' minH={"100vh"} w={"100vw"} >
                <Box
                    position={'fixed'}
                    top={0}
                    left={0}
                    w={'100vw'}
                    h={'80vh'}
                    zIndex={1}
                    bg={"linear-gradient(180deg, #252128 0%, rgba(36, 29, 50, 0) 100%)"}
                />
                <VStack zIndex={6} w="100%" spacing={3}>
                    <Header />
                    <TabNavigation />
                </VStack>
            </VStack>
            <ItineraryFooter />
        </>

    );
}
export default LandingPage;