import { Box, Flex, VStack, Button, Text, Slide } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import { useState, useRef, Suspense, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { AppContext } from '../../context/AppContext';
import InfluencerId from './InfluencerId';
import InfLocationDetail from './InfLocationDetail';

const moveUpDown = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
`;

const InfluencersVideo: React.FC = () => {
	const context: any = useContext(AppContext);
	const { currInf } = context;
	const [influencer, videoUrl, title] = currInf;

	const [isPlaceOpen, setIsPlaceOpen] = useState(false);
	const videoRef = useRef<HTMLVideoElement | null>(null);
	const navigate = useNavigate();

	const handleSwipeUp = () => {
		setIsPlaceOpen(true);
	};

	console.log(videoUrl);
	const handlers = useSwipeable({
		onSwipedUp: handleSwipeUp,
		trackMouse: true,
	});

	return (
		<Box
			id='story-container'
			position='relative'
			width='100vw'
			height='100vh'
			bg='black'
			overflow='hidden'
			display='flex'
			justifyContent='center'
			alignItems='center'
			{...handlers}>
			<Box
				position='fixed'
				top='0'
				left='0'
				width='100vw'
				height='50vh'
				zIndex={2}
				bg='linear-gradient(180deg, #111417 0%, rgba(7, 9, 11, 0) 100%)'
			/>
			<Box
				position='fixed'
				bottom='0'
				left='0'
				zIndex={2}
				width='100vw'
				height='45vh'
				bg='linear-gradient(0deg, #111417 7.37%, rgba(7, 9, 11, 0) 100%)'
			/>

			<Box position='absolute' top='50px' width='100%' px={3} zIndex={5}>
				<Button
					bg='inherit'
					variant='link'
					onClick={() => navigate('/home/CollectionStories')}>
					<img src='/icons/Back_Icon_White.svg' alt='back' />
				</Button>
			</Box>

			<Box position='absolute' width='100vw' height='100vh'>
				<Box
					as='video'
					ref={videoRef}
					src={videoUrl}
					autoPlay
					muted
					loop
					height='100%'
					width='100%'
					objectFit='cover'
				/>
				<Box position='absolute' bottom='10vh' w='100%' zIndex={5}>
					<Flex flexDir={'column'} px={4}>
						<Flex py={4}>
							<InfluencerId infId={influencer} />
						</Flex>
						<Text
							fontFamily='Poppins'
							fontSize='20px'
							fontWeight='700'
							lineHeight='25px'
							maxW={'330px'}
							color={'#FFFFFFED'}
							letterSpacing='0.23455415666103363px'
							textAlign='left'>
							{title}
						</Text>
					</Flex>
				</Box>
			</Box>

			<Flex
				position='absolute'
				bottom='20px'
				width='100%'
				justifyContent='center'
				alignItems='center'
				zIndex={5}>
				<VStack spacing={2}>
					<Button
						bg='inherit'
						variant='link'
						onClick={handleSwipeUp}
						animation={`${moveUpDown} 1s infinite`}>
						<img src='/icons/ArrowUp.svg' alt='swipe up' />
					</Button>
					<Text fontSize='13px' color='#9D9D9D' mb='17px' fontWeight='500'>
						SCROLL UP FOR MORE
					</Text>
				</VStack>
			</Flex>

			<Slide direction='bottom' in={isPlaceOpen} style={{ zIndex: 10 }}>
				<Box
					p={4}
					bg='#000000'
					h={'100vh'}
					overflowY={'auto'}
					overflowX={'hidden'}>
					<Suspense fallback={<div>Loading...</div>}>
						<InfLocationDetail />
					</Suspense>
				</Box>
			</Slide>
		</Box>
	);
};

export default InfluencersVideo;
