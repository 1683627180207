import React from 'react';
import { Tabs, TabList, Tab, Box, TabPanel, TabPanels } from '@chakra-ui/react';

import Collection from '../../pages/Collection';
import SearchBox from '../SearchBox';

const TabNavigation: React.FC = () => {
    return (
        <Box >
            <Tabs variant="unstyled" display="flex" flexDir={'column'} alignItems={'center'} justifyContent={'flex-start'}>
                <TabList bg="#29252D" display="flex" justifyContent={'space-between'} gap={4} borderRadius={'99px'} py={1} px={2} w={'94vw'}>
                    <Tab
                        bg="transparent"
                        color="#FFFFFFA1"
                        borderRadius="full"
                        py={3}
                        fontFamily="Poppins"
                        fontSize="13px"
                        lineHeight="26.94px"
                        letterSpacing="-0.5611507892608643px"
                        textAlign="left"
                        w={'45vw'}
                        fontWeight="500"
                        _selected={{
                            bg: 'rgba(255, 255, 255, 0.1)',
                            color: '#FFFFFF',
                            fontWeight: "600",
                            

                        }}
                        _hover={{
                            bg: 'rgba(255, 255, 255, 0.15)',
                        }}
                    >
                        Collections
                    </Tab>

                    <Tab
                        bg="transparent"
                        color="#FFFFFFA1"
                        borderRadius="full"
                        
                        py={3}
                        fontFamily="Poppins"
                        fontSize="13px"
                        lineHeight="26.94px"
                        letterSpacing="-0.5611507892608643px"
                        textAlign="left"
                        fontWeight="500"
                        w='45vw'
                        _selected={{
                            bg: 'rgba(255, 255, 255, 0.1)',
                            color: '#FFFFFF',
                            fontWeight: "600",
                            
                        }}
                        _hover={{
                            bg: 'rgba(255, 255, 255, 0.15)',
                        }}

                    >
                        Trending Videos
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <Collection />
                    </TabPanel>
                    {/* Panel for Saved Itineraries */}
                    <TabPanel>
                        <SearchBox />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    );
};

export default TabNavigation;
