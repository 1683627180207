import { Tabs, TabList, Tab, TabPanels, TabPanel, VStack, Box, Text, Flex } from "@chakra-ui/react";
import ListItinerary from "./ListItinerary";
import MyBucketList from "../../pages/MyBucketList";
import ItineraryFooter from "./ItineraryFooter";

const NavigationTabs = () => {
  return (
    <>
      <VStack bg='black' minH={"100vh"} w={"100vw"} pt={2}>
        <Box
          position={'fixed'}
          top={0}
          left={0}
          w={'100vw'}
          h={'50vh'}
          zIndex={'2'}
          bg={"linear-gradient(180deg, #252128 0%, rgba(36, 29, 50, 0) 100%)"}
        />
        <Tabs isLazy variant={'unstyled'} zIndex={5}>
          <TabList justifyContent="space-evenly" w={'100vw'} >
            <Tab
              sx={{ borderBottom: '1px solid #FFFFFF21', w: '100vw' }}
              _selected={{
                borderBottom: "2px solid #FFFFFFED",
              }}

            >
              <Flex direction="column" alignItems="flex-start">
                <Text color="#FFFFFF54"
                  fontFamily="Poppins"
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="18.62px"
                  letterSpacing="-0.5611507892608643px"
                  textAlign="left"
                >Your</Text>
                <Text
                  fontFamily="Poppins"
                  fontSize="14px"
                  fontWeight="700"
                  lineHeight="18.62px"
                  letterSpacing="-0.5611507892608643px"
                  textAlign="left"

                  as="span" color="#FFFFFFED" >
                  Bucketlists
                </Text>
              </Flex>
            </Tab>
            <Tab isFocusable={true}
              sx={{ borderBottom: '1px solid #FFFFFF21', w: '100vw' }}
              _selected={{
                borderBottom: "2px solid #FFFFFFED", // Bold border under the text
              }}
            >
              <Flex direction="column" alignItems="flex-start">
                <Text color="#FFFFFF54"
                  fontFamily="Poppins"
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="18.62px"
                  letterSpacing="-0.5611507892608643px"
                  textAlign="left"
                >Saved</Text>
                <Text
                  fontFamily="Poppins"
                  fontSize="14px"
                  fontWeight="700"
                  lineHeight="18.62px"
                  letterSpacing="-0.5611507892608643px"
                  textAlign="left"
                  as="span" color="#FFFFFFED" >
                  Itineraries
                </Text>
              </Flex>
            </Tab>
          </TabList>


          <TabPanels>
            <TabPanel>
              <MyBucketList />
            </TabPanel>

            {/* Panel for Saved Itineraries */}
            <TabPanel>
              <ListItinerary />
            </TabPanel>
          </TabPanels>
        </Tabs>
        <Box mt={20}/>
      </VStack>
      <ItineraryFooter />
    </>
  );
};
export default NavigationTabs;