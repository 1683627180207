import { Box, Flex, Text, SkeletonText } from "@chakra-ui/react";
import Header from "./Header";
import InputField from "./InputField";
import { useContext, useEffect, useRef } from "react";
import { AppContext } from "../../context/AppContext";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ChatCard from "./ChatCard";
import { useNavigate } from "react-router-dom";

const Chat: React.FC = () => {
  const navigate = useNavigate();
  const context: any = useContext(AppContext);
  const { chatEntries, isLoading } = context; // Global chat entries from context
  const bottomRef = useRef<HTMLDivElement>(null); // Ref to scroll to the bottom

  useEffect(() => {
    // Scroll to bottom when chatEntries change
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatEntries]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 200,
    centerMode: true,
    slidesToShow: 1.2,
    centerPadding: "15px",
    arrows: false,
    autoplay: false,
  };

  const handleNewChatButton = () => {
    // setChatEntries("");
    window.location.reload();

  }
  const handleChatButton=()=>{
    navigate('/home/savedConversation');  
  }

  return (
    <Flex bg={"#FFFFFF"} h="100vh">
      <Box
        bg={"#FFFFFF"}
        position={"fixed"}
        top={0}
        left={0}
        w={"100vw"}
        zIndex={5}
      >
        {/* Header remains fixed */}
        <Header
          backIconSrc="/icons/arrow_back.svg"
          chatIconSrc="/icons/chat_filled.svg"
          addIconSrc="/icons/plus_outline.svg"
          backNavigationPath="/home"
          chatButtonOnClick={handleChatButton}
          addButtonOnClick={handleNewChatButton}
          bgColor="#2F2F320D"
          borderColor="linear-gradient(135deg, #FF7E5F, #FEB47B)"
        />

        {/* Chat Topic Bar */}
        <Flex
          borderTop={"1px solid #3A3C4221"}
          mt={"5px"}
          pt={"8px"}
          pb={"7px"}
          pl={"18px"}
          boxShadow={"0px 7px 7px 0px #00000008"}
        >
          <Text
            fontSize={"11px"}
            fontFamily={"500"}
            letterSpacing={"-0.47px"}
            color={"#16181BA1"}
          >
            Hey There!
          </Text>
        </Flex>
      </Box>
      <Box bg={"#FFFFFF"} position={"fixed"} w="100vw" h="100vh">
        <Box
          flex={1}
          mt="70px"
          overflowY="auto" // Enable scrolling for inner content
          py={4}
          mb={"10px"}
          maxH="calc(100vh - 21vh)" // Limit the height so it doesn't exceed the viewport
        >
          {/* Display clickable chat entries */}
          <Box mt={"20px"}>
            {chatEntries.map(
              (
                entry: {
                  message: string;
                  type?: string;
                  via: string;
                  time: string;
                  highlights?: [];
                },
                index: number
              ) => (
                <Flex
                  justifyContent={entry.via === "user" ? "flex-end" : "flex-start"}
                  key={index} // Added key here
                  mb={2} // Margin between entries
                >
                  {(isLoading && entry.via === 'server' && entry.message === "...") ? (<SkeletonText height="40px" width="40vw" borderRadius="8px" p={"10px"} noOfLines={3} />) : (
                    <Flex
                      direction={"column"}
                      maxW={"100vw"}
                      overflowX={'hidden'}
                      mr={"12px"}
                    >

                      {/* For the Text */}
                      <Flex
                        justifyContent={entry.via === "user" ? "flex-end" : "flex-start"}
                      >
                        <Box
                          borderRadius={"8px"}
                          maxW={"227px"}
                          mx={1}
                          bg={entry.via === "server" ? "#E8E9FF" : "#E0F0EA"}>
                          <Text
                            color={"#16181B"}
                            fontSize={"14px"}
                            p={"10px"}
                            fontWeight={600}>
                            {entry.message}
                          </Text>
                        </Box>
                      </Flex>
                      {entry.type && (
                        <>
                          {entry.type === "TextR" && (
                            <Box pt="12px" pb={"12px"} h={"auto"}>
                              <Slider key={index} {...settings}>
                                {entry.highlights?.map((highlight: { title: string }, index: number) => (
                                  <ChatCard
                                    imageSrc=""
                                    altText=""
                                    title={highlight.title.replace(/<[^>]+>/g, '')} // Remove HTML tags
                                    subtitle=""
                                    buttonText=""
                                    onCardClick={() => navigate("/home/stories")}
                                  />
                                ))}
                              </Slider>
                            </Box>
                          )}
                        </>
                      )}
                      <Flex
                        justifyContent={entry.via === "user" ? "flex-end" : "flex-start"}
                      >
                        <Text
                          color={"#B5B5B5"}
                          fontSize={"11px"}
                          mt={1}
                          mx={1}
                          letterSpacing={"-0.47px"}
                        >
                          {entry.time}
                        </Text>
                      </Flex>
                    </Flex>
                  )}
                </Flex>
              )
            )}
            {/* Scroll reference element */}
            <div ref={bottomRef} />
          </Box>
        </Box>
      </Box>

      {/* Input Field */}
      <Box
        position={"fixed"}
        bottom={"10px"}
        bg={"white"}
        px={"31px"}
        py={3}
        w={"full"}
      >
        <InputField placeholder="Type here..." />
      </Box>
    </Flex>
  );
};

export default Chat;
