
import { Input, InputGroup, InputRightElement, IconButton } from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../context/AppContext';
import { formatDateTime } from '../../utilities/formatDateTime';
import { ArrowRightIcon } from '@chakra-ui/icons';

interface InputFieldProps {
    placeholder: string;
}

const InputField: React.FC<InputFieldProps> = ({
    placeholder
}) => {
    const context: any = useContext(AppContext);
    const { setChatEntries, setIsLoading, dateTime, setDateTime } = context; // Global chat entries from context
    const apiUrl = 'https://us-east1-traverse-project-421916.cloudfunctions.net/query-vector-db/query-ans';

    const navigation = useNavigate();
    const [inputValue, setInputValue] = useState('');
    useEffect(() => {
        const intervalId = setInterval(() => {
            const now = new Date();
            setDateTime(formatDateTime(now));
        }, 100);

        return () => clearInterval(intervalId);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const fetchChatResponse = async (query: string) => {
        setIsLoading(true);
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    query: query,
                    media: "all"
                })
            });

            if (!response.ok) {
                const text = await response.text();
                console.error('Error Response Body:', text);
                throw new Error(`HTTP error! status: ${response.status}, body: ${text}`);
            }

            const data = await response.json();
            const htmlString = data.data.answer.answer;

            // Create a temporary DOM element to parse the HTML
            const tempElement = document.createElement('div');
            tempElement.innerHTML = htmlString;

            // Extract the text content without the tags
            const textContent = tempElement.textContent || tempElement.innerText;
            const splitText = textContent.split(/(?<=\.|\?)/); // Splits by sentences and keeps the punctuation

            setChatEntries((prevEntries: any) => {
                // Create a copy of previous entries
                const newEntries = [...prevEntries];
                newEntries[newEntries.length - 1] = {
                    via: "server",
                    type:"TextR",
                    message: splitText,
                    highlights:data.data.answer.highlights,
                    time: dateTime
                };
                return newEntries;
            });
            console.log('Success:', data.data.user_query_chat_response.answer);
        } catch (error) {
            console.error('Fetch error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = () => {
        if (inputValue.trim() === '') return;

        setChatEntries((prevEntries: any) => [
            ...prevEntries,
            {
                via: "user",
                message: inputValue,
                time: dateTime
            },
            {
                via: "server",
                message: "...",
            },
        ]);
        console.log("date " + dateTime);
        fetchChatResponse(inputValue);
        setInputValue('');
        if (placeholder === "Ask here...") {
            navigation('/home/chat');
        }

    };

    useEffect(() => {
        // Perform fetch when inputValue changes and is not empty
        if (inputValue) {
            fetchChatResponse(inputValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <InputGroup >
            <Input
                value={inputValue}                          // Bind the input value to state
                onChange={(e) => setInputValue(e.target.value)}  // Update state on change
                placeholder={placeholder}
                borderRadius="99px"
                bg="#FFFFFF"
                _placeholder={{ color: '#42424254' }}
                boxShadow={"0px 48px 100px 0px #110C2E26"}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleSubmit();   // Handle enter key for submission
                        (e.target as HTMLInputElement).blur();
                    }
                }}
            />
            <InputRightElement

            >
                {inputValue.trim() === "" ? (
                    <IconButton
                        aria-label="Mic button"
                        icon={<img src="/icons/mic.svg" alt="mic" />}
                        borderRadius="full"
                        variant="ghost"
                        _hover={{ bg: 'gray.100' }}
                        onClick={() => { navigation("/home/VoiceAdvisor/") }}
                    />
                ) : (
                    <IconButton
                        aria-label="Send button"
                        icon={<ArrowRightIcon />}  // This is the send button
                        borderRadius="full"
                        variant="ghost"
                        _hover={{ bg: 'gray.100' }}
                        onClick={handleSubmit} // Send button functionality
                    />
                )}
            </InputRightElement>
        </InputGroup>

    );
};

export default InputField;
