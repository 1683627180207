import { Box, IconButton, Image, useDisclosure } from '@chakra-ui/react';
import { pixels } from '../../theme';
import { useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../context/AppContext';
import ModalWithBackdrop from '../ModalWithBackdrop';
import SigninComponent from '../SigninComponent';

const ItineraryFooter = () => {
	const router = useNavigate();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const location = useLocation();
	const context: any = useContext(AppContext);
	const { rOpen, setROpen,lOpen, setLOpen } = context;

	useEffect(() => {
		if (location.pathname === "/home")
			setROpen(false);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);

	const handleRightButton = () => {
		setLOpen(false);
		if (rOpen) {
			setROpen(false);
			router('/home');
		}
		else {
			setROpen(true);
			router('/home/navigate');
		}
	}
	const handleLeftButton = () => {
		setROpen(false);
		if (lOpen) {
			setLOpen(false);
			router('/home');
		}
		else {
			setLOpen(true);
			router('/home/page');
		}
	}

	const handleCenterButton = () => {
		const token = localStorage.getItem('token');
		if (token) {
			router('/home/traveladvisor');
		} else {
			onOpen(); // Open the modal
		}
	}
	return (
		<Box
			display={{ base: 'flex', md: 'none' }}
			bgImage={'/icons/Bottom_Sheet.png'}
			justifyContent={'space-between'}
			backgroundRepeat={'no-repeat'}
			backgroundSize={'cover'}
			alignItems={'center'}
			px={pixels['50pixels']}
			zIndex={9000}
			w={'100%'}
			h='86px'
			position='fixed'
			bottom={0}>

			{/* Left Side (Opaque) */}
			<IconButton onClick={handleLeftButton} aria-label={''} bg='none'>
			<Image src={lOpen ? "/icons/homeClicked.svg" : "/icons/homeUnselected.svg"} />
			</IconButton>

			{/* Middle Transparent Section with Circle */}
			<Box
				bg='transparent'
				h='100%'
				flexBasis='40%'
				display='flex'
				justifyContent='center'
				alignItems='center'
				position='relative'>
				<Box
					position='absolute'
					top='-30px' /* Adjust this to control how much the circle is raised above the footer */
					bg='transparent'
					h='100%'
					w='100%'
					display='flex'
					justifyContent='center'
					alignItems='center'>

					{/* Circular middle item */}
					<IconButton
						onClick={handleCenterButton}
						borderRadius={'50%'}
						h='max-content'
						w='max-content'
						aria-label={''}
						position={'absolute'}
						
						bg='none'>
						<Image src='/icons/Centre_Button.svg' />
					</IconButton>
				</Box>
			</Box>

			{/* Right Side (Opaque) */}
			<IconButton aria-label={''} onClick={handleRightButton} bg='none'>
				<Image src={rOpen ? "/icons/savedClicked.svg" : "/icons/ListUnselected.svg"} />
			</IconButton>

			<ModalWithBackdrop
				isOpen={isOpen}
				onClose={onClose}>
				<SigninComponent onClose={onClose} />
			</ModalWithBackdrop>

		</Box>
	);
};

export default ItineraryFooter;
