import { Heading, HStack, IconButton, Image } from "@chakra-ui/react";

const Header: React.FC = () => {
    return (
        <HStack bg={'#29252D'} px={6} pb={9} pt={7} w={'100vw'} justifyContent={'space-between'} borderBottomRadius={'33px'} >
            <HStack>
                <Image src='/icons/logo.svg' alt="logo" boxSize={'18px'} />
                <Heading
                    fontFamily="Poppins"
                    fontSize="16.78px"
                    fontWeight="600"
                    lineHeight="20.28px"
                    letterSpacing="-0.2097432017326355px"
                    textAlign="left"
                    color={'#FFFFFFED'}
                >
                    TRAVERSE
                </Heading>
            </HStack>
            <HStack>
                <IconButton
                    aria-label="Search"
                    icon={<img src="/icons/chat.svg" alt="chat" />}
                    borderRadius={'9.03px'}
                    color="white"
                    bg="#2E2B31"
                    _hover={{ bg: "transparent" }}
                    _active={{ bg: "transparent" }}
                    _focus={{ boxShadow: "none" }}
                />
                <IconButton
                    aria-label="Search"
                    icon={<img src="/icons/chat.svg" alt="chat" />}
                    borderRadius={'9.03px'}
                    color="white"
                    bg="#2E2B31"
                    _hover={{ bg: "transparent" }}
                    _active={{ bg: "transparent" }}
                    _focus={{ boxShadow: "none" }}
                />
            </HStack>
        </HStack>
    );
};
export default Header;
